$dark-gray: #16171c;
$primary-color: #ff0081;
$gray: #5e6878;
$highlighted-text: #0d6efd;
$secondary-color: #000036;
$light-black: #000;
@mixin inner-bg {
    height: 100%;
    background-color: $light-black;
    border-radius: 5px;
}
@mixin loader($strokeValue, $durationMedium, $durationLarge) {
    .loader-animation {
      position: relative;
      display: flex;
      color: #999;
      font-size: 20px;
      margin: auto;
      justify-content: center;
      .outer {
        -webkit-animation: load 10s;
        animation: load 10s;
        stroke-dasharray: $strokeValue;
        transition: stroke-dashoffset 1s;
      }
      .loader-icon {
        position: absolute;
        width: 60px;
        height: 60px;
        background-color: #fff;
        border-radius: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .shape-outline {
        position: absolute;
        border: 2px solid rgba(#fff, .4);
        width: 90px;
        height: 90px;
        border-radius: 100%;
        z-index: -1;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }
    }
}
@import 'constants.scss';
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Lato', sans-serif;
  font-variant-ligatures: none;
  margin: 0;
  cursor: default;
  user-select: none;
  overflow-x: hidden;
}

.hide {
  display: none !important;
}

.btn {
  position: relative;
  outline: 0;
  border: 0;
  font-size: 18px;
  font-weight: 700;
  font-family: inherit;
  font-variant-ligatures: none;
  padding: 14px 30px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ff0081;
  color: #fff;
  border-radius: 4px;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  box-shadow: 0 2px 25px rgba(255, 0, 130, 0.5);
  &:before,
  &:after {
    position: absolute;
    content: "";
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
  }
  &:before {
    display: none;
    top: -75%;
    background-image: radial-gradient(circle, #ff0081 20%, transparent 20%),
      radial-gradient(circle, transparent 20%, #ff0081 20%, transparent 30%),
      radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, #ff0081 15%, transparent 20%),
      radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%),
      radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
  }
  &:after {
    display: none;
    bottom: -75%;
    background-image: radial-gradient(circle, #ff0081 20%, transparent 20%),
      radial-gradient(circle, #ff0081 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, #ff0081 15%, transparent 20%),
      radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%),
      radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
  }
  &:active {
    transform: scale(0.9);
    background-color: #e60074;
    box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
  }
  &.animate:before {
    display: block;
    animation: topBubbles ease-in-out 0.75s forwards;
  }
  &.animate:after {
    display: block;
    animation: bottomBubbles ease-in-out 0.75s forwards;
  }
  &.btn-secondary {
    background-color: transparent;
    color: #fff;
    box-shadow: none;
    border: 2px solid $primary-color;
    &:hover {
      color: #fff;
      background-color: $primary-color;
    }
  }
  &.btn-disabled {
    background-color: transparent;
    color: $gray;
    pointer-events: none;
    box-shadow: 0 0 0 2px #5e6878;
  }
  &.btn-small {
    font-size: 16px;
    padding: 10px 16px;
  }
}

select {
  &:active {
    transform: scale(1) !important;
  }
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }

  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }

  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }

  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }

  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}


.main-wrapper {
  height: 503px;
  width: 800px;
  margin: auto;
  position: relative;
  // overflow: hidden;
  text-align: center;
  color: #414141;
  padding: 14px;
  border-radius: 10px;
  border: 1px dashed #8C8C8C;
  border-radius: 30px;
}
.container {
  max-width: 1700px;
  width: 89%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 1350px) { 
  .main-wrapper {
    width: 600px;
  }
 }
 @media (max-width: 1125px) { 
  .main-wrapper {
    width: 500px;
  }
 }
